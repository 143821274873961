<template>
  <div>

    <form-wizard
      color="#498ceb"
      :title="null"
      :subtitle="null"
      layout="horizontal"
      :finish-button-text="$t('message.submit')"
      :back-button-text="$t('message.previous')"
      class="wizard-vertical mb-3"
      @on-complete="formSubmitted"
      ref="wizardForm"
    >

      <tab-content
        :title="$t('label.identification')"
        :before-change="validateIdentificationForm"
      >
        <validation-observer
          ref="identificationTab"
          tag="form"
        >
          <Identification
            ref="identification"
            :data="data"
            :crimes="crimes"
            :areas="locations"
          />
        </validation-observer>
      </tab-content>

      <tab-content
        :title="$t('label.raci')"
        :before-change="validateRaciForm"
      >
        <validation-observer
          ref="raciTab"
          tag="form"
        >
          <RACI
            ref="raciRef"
            :workers="workersSupervised"
            :roles="roles"
            :data="data"
          />
        </validation-observer>
      </tab-content>

      <tab-content
        :title="$t('label.activities')"
        :before-change="validateActivitiesForm"
      >
        <validation-observer
          ref="activitiesTab"
          tag="form"
        >
          <Activities
            ref="activitiesRef"
            :data="reformatedData && reformatedData.activities ? reformatedData.activities : {}"
            @updActivities="(value) => addedActivities = value"
          />
        </validation-observer>
      </tab-content>

      <tab-content
        :title="$t('label.behaviours')"
        :before-change="validateBehavioursForm"
      >
        <validation-observer
          ref="behavioursTab"
          tag="form"
        >
          <Behaviours
            ref="behavioursForm"
            :data="reformatedData && reformatedData.behaviours ? reformatedData.behaviours : []"
            :added-activities="addedActivities"
          />
        </validation-observer>
      </tab-content>

    </form-wizard>

  </div>
</template>

<script>
import { ValidationObserver } from 'vee-validate'
import { FormWizard, TabContent } from 'vue-form-wizard'
import 'vue-form-wizard/dist/vue-form-wizard.min.css'
import useNotifications from '@/composables/useNotifications'
import Identification from '@/views/organization/process/shared/Identification.vue'
import RACI from '@/views/organization/process/shared/RACI.vue'
import Activities from '@/views/organization/process/shared/Activities.vue'
import Behaviours from '@/views/organization/process/shared/Behaviours.vue'
import { ref, onMounted, watch } from '@vue/composition-api'
import useCommon from '../../useCommon'
import i18n from '@/libs/i18n'

export default {
  components: {
    Identification,
    RACI,
    Activities,
    Behaviours,
    FormWizard,
    TabContent,
    ValidationObserver,
  },

  props: ['data', 'isSubmitting'],

  setup(props, { emit }) {
    const {
      getWorkersSupervisedForDropDown, workersSupervised, getRolesForDropDown, roles, getCrimesForDropDown, crimes, getLocationsForDropDown, locations
    } = useCommon()
    const { showErrorMessage } = useNotifications()

    const addedActivities = ref([])
    const identification = ref(null)
    const responsibilities = ref(null)
    const qualifications = ref(null)
    const identificationTab = ref(null)
    const activitiesTab = ref(null)
    const behavioursTab = ref(null)
    const behavioursForm = ref(null)
    const raciTab = ref(null)
    const raciRef = ref(null)
    const activitiesRef = ref(null)
    const reformatedData = ref({})
    const wizardForm = ref(null)

    onMounted(() => {
      getRolesForDropDown()
      getWorkersSupervisedForDropDown()
      getCrimesForDropDown()
      getLocationsForDropDown()
      // await getMetadataForDropDown({category: "process"})

      // Make all form tabs clickable
      if (wizardForm.value) {
        wizardForm.value.maxStep = wizardForm.value.tabCount - 1
        wizardForm.value.tabs.forEach(t => t.checked = true)
      }
    })

    const formSubmitted = async () => {
      if (props.isSubmitting) return

      const payload = {
        ...identification.value.getData(),
        ...raciRef.value.getData(),
        ...activitiesRef.value.getData(),
        ...behavioursForm.value.getData(),
      }

      /* INFO:: WHY THIS NEW FORMAT ?
       While this was reimplementing developer had changed the original data structure and
       at that moment backend was completed with previous structure. In order to have a common pattern we have reformatted
       the payload here.
       */
      // eslint-disable-next-line no-unused-vars
      const { raci, activities, behaviours } = payload
      const reformatPayload = payload.identification
      // reformatPayload.crimes = { link: reformatPayload.crimes?.map(e => e.value) || [] }
      // reformatPayload.areas = { link: reformatPayload.areas?.map(e => e.value) || [] }
      if (raci.responsible) reformatPayload.responsible = raci.responsible.value
      if (raci.leadResponsible) reformatPayload.leadResponsible = raci.leadResponsible.value
      if (raci.evaluator) reformatPayload.evaluator = raci.evaluator.value
      if (raci.evaluated) reformatPayload.evaluated = raci.evaluated.value
      // reformatPayload.role = { link: raci.role.value }
      reformatPayload.description = activities.description
      reformatPayload.controls = Array.from(new Set(activities.list || []))
      // reformatPayload.activities = activities.list.map((activity, index) => ({
      //   name: activity,
      //   behaviours: behaviours[index].behaviours,
      // }))
      // reformatPayload.requireImage = reformatPayload.requireImage.value
      // if (!reformatPayload.metadata.length) delete reformatPayload.metadata
      // else reformatPayload.metadata = reformatPayload.metadata.map(e => e.value)

      emit('submit', reformatPayload)
    }

    const validateIdentificationForm = async () => new Promise((resolve, reject) => {
      identificationTab.value.validate().then(success => {
        if (success) {
          resolve(true)
        } else {
          reject()
        }
      })
    })

    const validateRaciForm = async () => new Promise((resolve, reject) => {
      raciTab.value.validate().then(success => {
        if (success) {
          resolve(true)
        } else {
          reject()
        }
      })
    })

    const validateActivitiesForm = async () => new Promise((resolve, reject) => {
      // Check if there are missing or repeated controls
      const activitiesData = activitiesRef.value.getData()
      const controls = activitiesData?.activities?.list || []
      const controlRegistry = {}

      for (const control of controls) {
        if (!control) {
          showErrorMessage(i18n.t('message.requiredFieldsIncomplete'))
          return reject()
        }

        if (controlRegistry[control]) {
          showErrorMessage(i18n.t('message.controlsRepeated'))
          return reject()
        }

        controlRegistry[control] = 1
      }

      activitiesTab.value.validate().then(success => {
        if (success) {
          behavioursForm.value.createForm(controls)
          resolve(true)
        } else {
          reject()
        }
      })
    })

    const validateBehavioursForm = async () => new Promise((resolve, reject) => {
      behavioursTab.value.validate().then(success => {
        if (success) {
          resolve(true)
        } else {
          reject()
        }
      })
    })

    watch(() => props.data, value => {
      /* INFO:: WHY THIS NEW FORMAT ?
       While this was reimplementing developer had changed the original data structure and
       at that moment backend was completed with previous structure. In order to have a common pattern we have reformatted
       the payload here.
       */
      if (value) {
        reformatedData.value.identification = {
          name: value.name,
          code: value.code,
          goal: value.goal,
          assumptions: value.assumptions,
        }

        reformatedData.value.raci = {
          role: value.role,
          responsible: value.responsible,
          leadResponsible: value.leadResponsible,
          evaluator: value.evaluator,
          evaluated: value.evaluated,
        }
        // reformatedData.value.activities = { description: value.description, list: value.activities.map(activity => activity.name) }
        reformatedData.value.activities = { description: value.description, list: value.controls }
        // reformatedData.value.behaviours = value.activities.map(activity => ({
        //   activity: activity.name,
        //   behaviours: activity.behaviours,
        // }))
      }
    }, { deep: true, immediate: true })

    return {
      formSubmitted,
      identification,
      responsibilities,
      qualifications,
      identificationTab,
      raciTab,
      raciRef,
      activitiesRef,
      activitiesTab,
      behavioursTab,
      validateIdentificationForm,
      validateRaciForm,
      validateActivitiesForm,
      validateBehavioursForm,
      workersSupervised,
      roles,
      // metadata,
      addedActivities,
      behavioursForm,
      reformatedData,
      crimes,
      locations,
      wizardForm,
    }
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-wizard.scss";
@import "@core/scss/vue/libs/vue-select.scss";
</style>
